"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackgroundColors = void 0;
exports.BackgroundColors = {
    GREEN: '#256f3e',
    ORANGE: '#934b10',
    YELLOW: '#edb508',
    INDIGO: '#9b9bd9',
    LIME: '#d0e595',
    MINT: '#9bd4c2',
    PINK: '#e99be7',
    RED: '#93ebeb',
    TEAL: '#ff8f8f',
    VIOLET: '#b98ae5',
};
